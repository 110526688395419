import React, { useEffect, useState } from 'react';
import {
  Switch, Tooltip, Tabs, Tab,
} from '@mui/material';
import {
  DataGridPro,
} from '@mui/x-data-grid-pro';
// import { TrackstarConnectButton } from '@trackstar/react-trackstar-link';
import { useFetch } from './api';
import SyncConnectionModal from './SyncConnectionModal';
import DeleteObjectModal from './DeleteObjectModal';

function NoErrorsMessage() {
  return (
    <span className="flex justify-center align-center">No Errors</span>
  );
}

function ConnectionDetailDrawer({
  showConnectionDetail, connectionDetail, fetchConnectionData,
  closeDrawer, reinstallButton,
}) {
  const trackstar = useFetch();
  const [errorRows, setErrorRows] = React.useState([]);
  const [scheduleRows, setScheduleRows] = React.useState([]);
  const [scheduleRowsLoading, setScheduleRowsLoading] = React.useState(true);
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [rowToSync, setRowToSync] = useState(null);
  const [resourceToSync, setResourceToSync] = useState(null);
  const [accessToken, setAccessToken] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeletingRow, setIsDeletingRow] = useState(false);
  const [logo, setLogo] = useState('ts_logo_2024.png');
  const [tabIndex, setTabIndex] = useState(0);
  // const [trackstarConnectButton, setTrackstarConnectButton] = useState(null);

  const getConnectionSchedules = () => {
    setScheduleRowsLoading(true);
    trackstar(`internal/schedules/${connectionDetail.connection_id}`, 'GET').then((response) => {
      const newScheduleRows = response.data.map((schedule) => (
        { id: schedule.resource_name, ...schedule }));
      setScheduleRows(newScheduleRows);
      setScheduleRowsLoading(false);
    });
  };

  const getAccessToken = () => {
    trackstar(`internal/access-token?connection_id=${connectionDetail.connection_id}&integration_name=${connectionDetail.integration_name}`, 'GET').then((response) => {
      setAccessToken(response.access_token);
    });
  };

  const copyAccessToken = () => {
    navigator.clipboard.writeText(accessToken);
    alert('Access Token copied to clipboard');
  };

  const handleOpenSyncModal = (resource) => {
    setSyncModalOpen(true);
    setRowToSync(connectionDetail);
    setResourceToSync(resource);
  };

  const handleCloseSyncModal = (cancelled) => {
    if (!cancelled) {
      fetchConnectionData();
    }
    setSyncModalOpen(false);
    setRowToSync(null);
    setResourceToSync(null);
  };

  const handleSync = () => {
    trackstar(`internal/connections/sync/${resourceToSync.connection_id}`, 'POST', { functions_to_sync: [resourceToSync.function_name] }).then(() => {
      const newScheduleRows = scheduleRows.map((schedule) => (
        {
          id: schedule.resource_name,
          sync_started: schedule.resource_name === resourceToSync.resource_name,
          ...schedule,
        }
      ));
      setScheduleRows(newScheduleRows);
      handleCloseSyncModal(false);
    });
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = (cancelled) => {
    if (!cancelled) {
      fetchConnectionData();
    }
    setDeleteModalOpen(false);
  };

  const handleDeleteConnection = () => {
    setIsDeletingRow(true);
    trackstar(`internal/connections/${connectionDetail.connection_id}`, 'DELETE').then(() => {
      handleCloseDeleteModal(false);
      setIsDeletingRow(false);
      closeDrawer();
    });
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const readableFrequency = (frequency) => {
    const frequencyNum = Number(frequency);
    const hours = Math.floor(frequencyNum / 3600);
    const minutes = Math.floor((frequencyNum % 3600) / 60);
    const hoursDisplay = hours > 0 ? `${hours}h ` : '';
    const minutesDisplay = minutes > 0 ? `${minutes}m` : '';
    return `${hoursDisplay}${minutesDisplay}`;
  };

  useEffect(() => {
    if (showConnectionDetail) {
      getConnectionSchedules();
      getAccessToken();
      setErrorRows(connectionDetail.errors.map((error, index) => ({ id: index, ...error })));
      if (connectionDetail !== 'sandbox') {
        setLogo(`https://trackstarlogosbucket.s3.amazonaws.com/${connectionDetail.integration_name}.png`);
      }
    }
  }, [showConnectionDetail]);

  // useEffect(() => {
  //   if (connectionDetail) {
  //     setTrackstarConnectButton(
  //       <TrackstarConnectButton
  //         buttonId="reinstall-connection"
  //         getLinkToken={async () => {
  //           const linkToken = await trackstar(
  //             'internal/link/token',
  //             'POST',
  //             { connection_id: connectionDetail.connection_id },
  //           )
  //             .then((response) => response.link_token);
  //           return linkToken;
  //         }}
  //         onSuccess={async (authCode) => {
  //           await trackstar('internal/link/exchange', 'POST', { auth_code: authCode })
  //             .then((response) => {
  //               handleReinstall(response);
  //             });
  //         }}
  //         onClose={() => console.log('closed')}
  //         onLoad={() => console.log('loaded')}
  //         style={{
  //           border: '0px',
  //           padding: '.5rem',
  //         }}
  //         className="flex justify-center items-center h-6 w-6 p-2"
  //       >
  //         <input type="image" src="fix.png" alt="reinstall connection" className="h-5 w-5" />
  //       </TrackstarConnectButton>,
  //     );
  //   }
  // }, [connectionDetail]);

  return (
    <div className="flex flex-col p-4 space-y-3">
      <div className="flex justify-between">
        <div className="flex justify-self-center items-self-center items-center px-1 space-x-2">
          <Tooltip title={connectionDetail?.integration_name} arrow>
            <img src={logo} alt={`${connectionDetail?.integration_name} logo`} className="h-6 w-6" />
          </Tooltip>
          <h1 className="font-medium text-gray-600 overflow-hidden text-ellipsis max-w-lg">{connectionDetail?.connection_id}</h1>
        </div>
        <div className="flex space-x-1 justify-self-end items-center">
          <Tooltip title={connectionDetail?.webhooks_disabled ? 'Enable Webhooks' : 'Disable Webhooks'} arrow>
            <Switch
              checked={!connectionDetail?.webhooks_disabled}
              onChange={() => {
                trackstar(`internal/connections/${connectionDetail.connection_id}`, 'PATCH', { webhooks_disabled: !connectionDetail.webhooks_disabled }).then(() => {
                  fetchConnectionData();
                });
              }}
              className="-mr-2"
              sx={{
                '& .MuiSwitch-colorPrimary': {
                  '&.Mui-checked': {
                    color: '#8B5CF6',
                  },
                  '&.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#8B5CF6',
                  },
                },
                '& .MuiSwitch-thumb': {
                  backgroundImage: 'url(\'webhooks.png\')',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: '100%',
                },
                '& .MuiSwitch-switchBase': {
                  '&.Mui-checked': {
                    '& .MuiSwitch-thumb': {
                      backgroundImage: 'url(\'webhooks-white.png\')',
                    },
                  },
                },
              }}
            />
          </Tooltip>
          <Tooltip title="Copy Access Token" arrow>
            <input type="image" alt="copy access token" src="key.png" id="access-token" className="h-6 w-6" onClick={copyAccessToken} />
          </Tooltip>
          <Tooltip title="Reinstall Connection" arrow>
            <span>
              {reinstallButton}
            </span>
          </Tooltip>
          <Tooltip title="Delete Connection" arrow>
            <input type="image" alt="delete connection" src="trash.png" className="h-6 w-6" onClick={handleOpenDeleteModal} />
          </Tooltip>
          <input type="image" alt="close drawer" src="close.png" className="h-6 w-6" onClick={closeDrawer} />
        </div>
      </div>
      <div className="flex space-x-10 items-center my-2">
        <div>
          <button className={`px-3 py-1 border rounded-lg ${connectionDetail?.errors.length > 0 ? 'border-trackstarBadDark text-trackstarBadDark' : 'border-trackstarOKDark text-trackstarOKDark'}`} type="button" onClick={() => handleTabChange(null, 1)}>{connectionDetail?.errors.length > 0 ? 'Errors' : 'OK'}</button>
        </div>
        <div className="items-center space-x-2 ">
          <span className="cardLabel ml-2">Created Date</span>
          <h1 className="font-medium text-sm ">{new Date(connectionDetail?.created_at).toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' })}</h1>
        </div>
        <div className="items-center space-x-2">
          <span className="cardLabel ml-2">API Calls</span>
          <h1 className="font-medium text-sm ">{connectionDetail?.times_used || 0}</h1>
        </div>
        <div className="items-center space-x-2">
          <span className="cardLabel ml-2">Last Used</span>
          <h1 className="font-medium text-sm ">{connectionDetail?.last_used ? new Date(connectionDetail.last_used).toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' }) : 'Never'}</h1>
        </div>
      </div>
      <div style={{ minHeight: '150px' }}>
        <Tabs value={tabIndex} onChange={handleTabChange} sx={{ '& .MuiTabs-indicator': { backgroundColor: '#7F56D9' } }}>
          <Tab label="Sync Schedules" index={0} sx={{ textTransform: 'none', color: '#7F56D9', '&.Mui-selected': { color: '#7F56D9' } }} />
          <Tab label="Sync Errors" index={1} sx={{ textTransform: 'none', color: '#7F56D9', '&.Mui-selected': { color: '#7F56D9' } }} />
        </Tabs>
        {tabIndex === 0 && (
        <DataGridPro
          rows={scheduleRows}
          autoHeight
          loading={scheduleRowsLoading}
          disableColumnMenu
          columns={[
            {
              field: 'resource_name', headerName: 'Resource Name', flex: 2,
            },
            {
              field: 'last_crawl_start',
              headerName: 'Last Sync Start',
              flex: 2,
              valueFormatter: (params) => {
                const { value } = params;
                if (!value) {
                  return 'No data';
                }
                const date = new Date(value);
                return date.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });
              },
              renderHeader: () => (
                <div className="flex items-center gap-2">
                  Last Sync Start
                  <Tooltip
                    title={(
                      <span style={{ fontSize: '1rem' }}>
                        {' '}
                        {/* Adjust font size as needed */}
                        The time the most recent sync started.
                      </span>
                    )}
                    arrow
                  >
                    <img id="last-sync-start-tip" src="info.png" alt="last sync start" className="h-5 w-5" />
                  </Tooltip>
                </div>
              ),
            },
            {
              field: 'last_crawl_end',
              headerName: 'Last Sync End',
              flex: 2,
              valueFormatter: (params) => {
                const { value } = params;
                if (!value) {
                  return 'No data';
                }
                const date = new Date(value);
                return date.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });
              },
              renderHeader: () => (
                <div className="flex items-center gap-2">
                  Last Sync End
                  <Tooltip
                    title={(
                      <span style={{ fontSize: '1rem' }}>
                        {' '}
                        {/* Adjust font size as needed */}
                        The time the most recent sync completed.
                      </span>
                    )}
                    arrow
                  >
                    <img id="last-sync-end-tip" src="info.png" alt="last sync end" className="h-5 w-5" />
                  </Tooltip>
                </div>
              ),
            },
            {
              field: 'crawl_frequency',
              headerName: 'Sync Frequency',
              flex: 2,
              valueFormatter: (params) => {
                const { value } = params;
                return `Every ${readableFrequency(value)}`;
              },
              renderHeader: () => (
                <div className="flex items-center gap-2">
                  Sync Frequency
                  <Tooltip
                    title={(
                      <span style={{ fontSize: '1rem' }}>
                        {' '}
                        {/* Adjust font size as needed */}
                        The frequency at which this resource syncs.
                      </span>
                    )}
                    arrow
                  >
                    <img id="sync-frequency-tip" src="info.png" alt="sync frequency" className="h-5 w-5" />
                  </Tooltip>
                </div>
              ),
            },
            {
              field: 'sync',
              headerName: 'Sync',
              flex: 1.25,
              renderCell: (params) => {
                if (params.row.sync_started
                  || params.row.last_crawl_start > params.row.last_crawl_end) {
                  return (
                    <span className="px-2 py-1 w-fit border rounded-lg border-trackstarOKDark text-trackstarOKDark" style={{ fontSize: '20' }}>
                      In Progress
                    </span>
                  );
                }
                return <input type="image" alt="sync resource" src="regenerate_purple.png" className="h-6 w-6" onClick={() => handleOpenSyncModal(params.row)} />;
              },
              renderHeader: () => (
                <div className="flex items-center gap-2">
                  Sync
                  <Tooltip
                    title={(
                      <span style={{ fontSize: '1rem' }}>
                        {' '}
                        {/* Adjust font size as needed */}
                        Click to manually sync this resource.
                        If the resource is already syncing, it will show [In Progress].
                      </span>
                    )}
                    arrow
                  >
                    <img id="sync-tip" src="info.png" alt="sync" className="h-5 w-5" />
                  </Tooltip>
                </div>
              ),
            },
          ]}
          className="shadow-sm"
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#F5F5F5',
              borderRadius: '0px !important',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
              fontWeight: '400',
              fontSize: '13px',
              color: '#6b7280',
            },
            '& .column-header-no-border .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            borderRadius: '0px !important',
            '& .MuiDataGrid-cell': {
              fontSize: '13px',
              fontWeight: '400',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
            },
          }}
          hideFooter
        />
        )}
        {tabIndex === 1 && (
        <DataGridPro
          rows={errorRows}
          getRowHeight={() => 'auto'}
          autoHeight
          slots={{
            noRowsOverlay: NoErrorsMessage,
          }}
          columns={[
            {
              field: 'error_message', headerName: 'Error Message', width: 200,
            },
            {
              field: 'affected_endpoints',
              renderHeader: () => (
                <div className="flex items-center gap-2">
                  <div>Affected Endpoints</div>
                  <Tooltip
                    title={(
                      <span style={{ fontSize: '1rem' }}>
                        {' '}
                        {/* Adjust font size as needed */}
                        Trackstar endpoints affected by this connection error.
                        {' '}
                        <br />
                        If red, it means no data is able to be synced for that endpoint.
                        {' '}
                        <br />
                        If gray, data can be synced but some fields may be missing.
                      </span>
  )}
                    arrow
                  >
                    <img id="affected-endpoints-tip" src="info.png" alt="affected endpoints" className="h-5 w-5" />
                  </Tooltip>
                </div>
              ),
              width: 250,
              renderCell: (params) => {
                const { value } = params;
                return (
                  <div>
                    {value.map((val) => (
                      <button
                        className={`px-3 py-1 mx-1 my-1 border rounded-2xl ${
                          val.preventing_sync
                            ? 'border-trackstarBadDark text-trackstarBadDark'
                            : ''
                        }`}
                        type="button"
                        disabled
                        title={val.preventing_sync ? 'Sync is prevented' : 'Sync successful but some fields may be missing'}
                        style={{ cursor: 'pointer' }}
                      >
                        {val.endpoint}
                      </button>
                    ))}
                  </div>
                );
              },
            },
            {
              field: 'first_seen',
              headerName: 'First Seen',
              flex: 1,
              valueFormatter: (params) => {
                const { value } = params;
                const date = new Date(value);
                return date.toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });
              },
            },
          ]}
          className="shadow-sm"
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#F5F5F5',
              borderRadius: '0px !important',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
              fontWeight: '400',
              fontSize: '13px',
              color: '#6b7280',
            },
            '& .column-header-no-border .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            borderRadius: '0px !important',
            '& .MuiDataGrid-cell': {
              fontSize: '13px',
              fontWeight: '400',
              padding: '10px',
              fontFamily: 'ui-sans-serif, system-ui, sans-serif',
            },
          }}
          hideFooter
        />
        )}
      </div>
      <SyncConnectionModal
        isOpen={syncModalOpen}
        onClose={handleCloseSyncModal}
        onSync={handleSync}
        isLoading={false}
        row={rowToSync}
        selectedResource={resourceToSync?.resource_name}
      />
      <DeleteObjectModal
        isOpen={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDelete={handleDeleteConnection}
        isLoading={isDeletingRow}
        row={connectionDetail}
        objectType="Connection"
      />
    </div>
  );
}

export default ConnectionDetailDrawer;
